import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import { useHits } from "react-instantsearch";

import { CROSSBREED_UUIDS } from "../../../../../func-core/core/domain/constants/breeds";
import RadioFieldset from "../../../../../molecules/radio-fieldset/RadioFieldset";
import { useBreedSelection } from "../../../application/breed-selection-use-cases";
import * as styles from "./CrossbreedRadioGroup.module.scss";

interface CrossbreedRadioGroupProps {
  selectHit: (uuid: string, name: string) => void;
  language: string;
}

const CrossbreedRadioGroup = ({ selectHit, language }: CrossbreedRadioGroupProps): ReactElement => {
  const { t } = useTranslation();
  const { hits } = useHits();
  const [breedSelection] = useBreedSelection();

  const hitsList = hits.filter((hit: any) => CROSSBREED_UUIDS.includes(hit.uuid_key));

  const handleChange = (value: string): void => {
    const hit: any = hits.find((hit: any) => hit.uuid_key === value);

    if (!hit) {
      return;
    }

    selectHit(value, hit[`name_${language}`]);
  };

  return (
    <RadioFieldset
      name="crossbreed"
      options={hitsList.map((hit: any) => ({
        value: hit.uuid_key,
        title: t(`unknown_breed_selector.weight_group.${hit.weight_group.toLowerCase()}`),
        key: hit.key,
      }))}
      legend={t("crossbreed_radio_group.aria_label")}
      initialValue={
        breedSelection.petBreed.kbKey.length ? breedSelection.petBreed.kbKey : undefined
      }
      onChange={(value: string) => handleChange(value)}
      adoptionClassName={styles.radioFieldset}
    />
  );
};

export default CrossbreedRadioGroup;
