import algoliasearch from "algoliasearch/lite";
import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useRef } from "react";
import { Configure, InstantSearch } from "react-instantsearch";

import { useCountry } from "../../../../../func-core/core/application/hooks/useCountry";
import { PetSpecies } from "../../../../../settings/pet";
import AlternativeBreedHits from "./AlternativeBreedHits";
import AlternativeBreedSearchBox from "./AlternativeBreedSearchBox";
import * as styles from "./AlternativeBreedSelect.module.scss";

const gastbyAlgoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? "";
const gastbyAlgoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? "";
const searchClient = algoliasearch(gastbyAlgoliaAppId, gastbyAlgoliaSearchKey);

interface BreedSelectProps {
  species: PetSpecies;
  selectBreedUuid: (uuid: string, name: string, formControlName: string) => void;
  adoptionClassName?: string;
  label?: string;
  formControlName: string;
}

const AlternativeBreedSelect = ({
  species,
  selectBreedUuid,
  adoptionClassName,
  label,
  formControlName,
}: BreedSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { country } = useCountry();

  const language = country.language.code.split("-")[0].toLowerCase();
  const nameAttribute = `name_${language}`;
  const synonymsAttribute = `synonyms_${language}`;
  const searchableAttributes = [nameAttribute, synonymsAttribute].filter(
    (attr) => attr in searchClient.initIndex("breeds")
  );

  const selectHit = (uuid: string, name: string): void => {
    selectBreedUuid(uuid, name, formControlName);
  };

  const toggleBreedHits = (): void => {
    ref.current?.classList.toggle("isBreedHitsOpen");
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={`breeds`}>
      <Configure
        filters={`species:${species}`}
        restrictSearchableAttributes={searchableAttributes}
        attributesToRetrieve={[`name_${language}`, `synonyms_${language}`, "uuid_key"]}
      />
      <div ref={ref} className={cn(styles.breedSelect, adoptionClassName)}>
        <AlternativeBreedSearchBox
          label={label ?? t("onboarding.breed.field.title")}
          formControlName={formControlName}
          toggleBreedHits={toggleBreedHits}
        />
        <AlternativeBreedHits formControlName={formControlName} selectHit={selectHit} />
      </div>
    </InstantSearch>
  );
};

export default AlternativeBreedSelect;
