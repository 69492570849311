import algoliasearch from "algoliasearch/lite";
import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import { Configure, InstantSearch } from "react-instantsearch";

import { useCountry } from "../../../../../func-core/core/application/hooks/useCountry";
import { PetSpecies } from "../../../../../settings/pet";
import CrossbreedRadioGroup from "../../atoms/crossbreed-radio-group/CrossbreedRadioGroup";
import * as styles from "./UnknownBreedSelector.module.scss";

const gastbyAlgoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? "";
const gastbyAlgoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? "";
const searchClient = algoliasearch(gastbyAlgoliaAppId, gastbyAlgoliaSearchKey);

interface UnknownBreedSelectorProps {
  selectBreedUuid: (uuid: string, name: string, formControlName: string) => void;
  species: PetSpecies;
  adoptionClassName?: string;
  formControlName?: string;
}

const UnknownBreedSelector = ({
  selectBreedUuid,
  species,
  adoptionClassName,
  formControlName = "petBreed",
}: UnknownBreedSelectorProps): ReactElement => {
  const { t } = useTranslation();
  const { country } = useCountry();

  const language = country.language.code.replace("-", "_").toLocaleLowerCase();
  const nameAttribute = `name_${language}`;
  const synonymsAttribute = `synonyms_${language}`;
  const searchableAttributes = [nameAttribute, synonymsAttribute].filter(
    (attr) => attr in searchClient.initIndex("breeds")
  );
  const defaultCatBreedName = t("unknown_breed_selector.cat.default_breed_name");

  const selectHit = (uuid: string, name: string): void => {
    selectBreedUuid(uuid, name, formControlName);
  };

  return (
    <>
      {species === PetSpecies.dog ? (
        <InstantSearch searchClient={searchClient} indexName={`breeds`}>
          <Configure
            filters={`species:${species}`}
            restrictSearchableAttributes={searchableAttributes}
            attributesToRetrieve={[
              `name_${language}`,
              `synonyms_${language}`,
              "uuid_key",
              "weight_group",
            ]}
          />
          <div className={cn(adoptionClassName)}>
            <p className={styles.weightQuestion}>{t("unknown_breed_selector.question")}</p>
            <CrossbreedRadioGroup selectHit={selectHit} language={language} />
          </div>
        </InstantSearch>
      ) : (
        <p className={styles.catMessage}>
          {t("unknown_breed_selector.cat.assign_message", { petBreed: defaultCatBreedName })}
        </p>
      )}
    </>
  );
};

export default UnknownBreedSelector;
